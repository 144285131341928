import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "v-left-col" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 2,
  class: "v-is-bespoke"
}
const _hoisted_6 = { class: "v-main-col" }
const _hoisted_7 = {
  key: 0,
  class: "sp-carer-timeline-badges"
}
const _hoisted_8 = { class: "v-preferred-name" }
const _hoisted_9 = {
  key: 1,
  class: "v-client"
}
const _hoisted_10 = { class: "v-main-title" }
const _hoisted_11 = { class: "v-preferred-name" }
const _hoisted_12 = {
  key: 3,
  class: "v-main-title"
}
const _hoisted_13 = { key: 4 }
const _hoisted_14 = {
  key: 0,
  class: "v-right-col__compact"
}
const _hoisted_15 = {
  key: 1,
  class: "v-right-col__assessment"
}
const _hoisted_16 = {
  key: 2,
  class: "v-right-col__assessment"
}
const _hoisted_17 = {
  key: 3,
  class: "v-right-col"
}
const _hoisted_18 = {
  key: 0,
  class: "v-content"
}
const _hoisted_19 = { class: "v-main-col" }
const _hoisted_20 = { class: "v-additional-info-content" }
const _hoisted_21 = { "data-id": "skinInstanceLocation" }
const _hoisted_22 = { "data-id": "skinInstanceSpecificType" }
const _hoisted_23 = {
  key: 0,
  class: "v-list-entry-additional-info"
}
const _hoisted_24 = { class: "v-left-col-additional-info" }
const _hoisted_25 = { key: 0 }
const _hoisted_26 = {
  key: 0,
  class: "v-responsible-people-count"
}
const _hoisted_27 = { class: "v-main-col" }
const _hoisted_28 = { class: "v-additional-info-content" }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 1 }
const _hoisted_31 = ["id"]
const _hoisted_32 = { class: "v-note-public" }
const _hoisted_33 = {
  key: 1,
  class: "sp-note-public v-note-public-text"
}
const _hoisted_34 = { class: "v-right-col-additional-info" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import ClientBadges from '@/clients/components/ClientBadges.vue';
import ConfidentialBadge from '@/timeline/components/ConfidentialBadge.vue';
import { useRoute, useRouter } from 'vue-router';
import IInteraction from '@/timeline/types/IInteraction';
import {
  CoercedChoice,
  Parameter,
} from '@/timeline/types/Parameter';
import BaseAvatar from '@/_shared/components/display/BaseAvatar.vue';
import { loadModuleLanguageFileAsync } from '@/_shared/translations/i18nLoading';
import use from '@/_shared/compositionApi';
import {
  computed, PropType, ref, onBeforeMount, watch,
} from 'vue';
import {
  getScoreCssClass,
  hasTotalScoreStyle,
  isParameterFilled,
} from '@/timeline/helper/parametersHelper';
import {
  additionalInfoPresent,
  formatDisplayTime,
  interactionLink,
  interactionStateCss,
  overdueTimeToWords,
  isSnoozed,
  responsiblePersonId,
  filledParametersPresent,
} from '@/timeline/helper/interactionsHelper';
import { format } from 'date-fns';
import useUserStore from '@/_shared/store/user';
import { Alarm } from '@/timeline/types/Alarm';
import { providedServicesStore } from '@/_shared/store/providedServices';
import useCategoryIconStore from '@/_shared/store/categoryIcons';
import ParameterInfo from '@/timeline/components/ParameterInfo.vue';
import clientStore from '@/_shared/store/clients';
import BaseCheckbox from '@/_shared/components/inputs/BaseCheckbox.vue';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import { storeToRefs } from 'pinia';
import { safeTranslate } from '@/_shared/translations/i18n'; // Fallback to codename
import { personStore } from '@/_shared/store/people';
import { instancesStore } from '@/_shared/store/Instances';
import HandoverFlag from './HandoverFlag.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionsListEntry',
  props: {
  interaction: {
    type: Object as PropType<IInteraction>,
    required: true,
  },
  compact: {
    type: Boolean,
    required: false,
    default: false,
  },
  assessments: {
    type: Boolean,
    required: false,
    default: false,
  },
  carerTimeline: {
    type: Boolean,
    required: false,
    default: false,
  },
  familyTimeline: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  setup(__props) {

_useCssVars(_ctx => ({
  "15bc8e8d": (isBespokeTop.value)
}))

onBeforeMount(async () => {
  await loadModuleLanguageFileAsync('timeline'); // TODO WHEN PURE VUE remove
});

const categoryIconStore = useCategoryIconStore();

const props = __props;

const nourishInstance = computed(() => {
  if (props.interaction.nourishInstanceId && props.interaction.clientId) {
    return instancesStore.clientInstanceByInstanceId(props.interaction.clientId, props.interaction.nourishInstanceId);
  }
  return undefined;
});

const preferredName = computed(() => clientStore.preferredOrFirstName(props.interaction.clientId).value);
const fullName = computed(() => clientStore.fullName(props.interaction.clientId).value);
const categoryCodename = providedServicesStore.categoryCodename(props.interaction?.providedServiceId);

const computedParameters = ref<Parameter[]>(props.interaction?.computedParameters?.filter((parameter: Parameter) => parameter.id !== 'actions'));
const hasPerformedComputedParameterValues = computed(() => props.interaction.state !== 'planned' && computedParameters.value?.length > 0 && props.interaction.savedAt && !props.assessments);

const nourishStore = useUserStore();

const route = useRoute();
const router = useRouter();

const interactionSelectedClasses = computed(() => (route?.params?.id === props.interaction.id?.toString() ? 'v-interaction-selected' : ''));
const isPlanned = computed(() => props.interaction.state === 'planned' || props.interaction.state === 'sticky');
const showNotePublic = computed(() => !!props.interaction.notePublic);
const isBespoke = computed(() => {
  if (props.interaction.userDefined === false) return false;
  return (!props.interaction.serviceScheduleId && !props.interaction.needId && props.interaction.state !== 'sticky' && !props.interaction?.protocolParentId);
});
const hasResponsiblePeople = computed(() => props.interaction.responsiblePersonIds?.length);
const showHandover = computed(() => props.interaction.handover
  && nourishStore.userHasPermission('viewHandovers'));
const hasAdditionalInfo = computed(() => additionalInfoPresent(props.interaction) && !props.compact);
const isClosedWithFilledParameters = computed(() => filledParametersPresent(props.interaction, true) && !props.assessments);
const interactionStateClasses = computed(() => interactionStateCss(props.interaction));
const sortedParameters = computed(() => {
  let paramToDisplay = props.interaction.parameters;
  paramToDisplay = filterParamsWithValueToDisplay(paramToDisplay);
  return [...paramToDisplay].sort((a, b) => (a.position - b.position));
});

const { featureToggles } = storeToRefs(useUserStore());
const showConfidentialityBadge = computed(() => featureToggles.value.confidentialInteractions && props.interaction.confidential);

const filterParamsWithValueToDisplay = (params: Parameter[]) => {
  const checkClosed = props.interaction.state === 'closed' || props.interaction.state === 'ok';
  return params.filter((p: Parameter) => p.valueType !== 'read_only_text'
    && (isParameterFilled(p, props.interaction.closedAt, checkClosed, props.interaction.hideEmptyParameters)
      || (p.valueType === 'file' && !props.interaction.hideEmptyParameters)
      || (p.valueType === 'picture' && !props.interaction.hideEmptyParameters)));
};

const serviceCountsTimeline: boolean = route?.name ? (route.name as string).includes('serviceCountList') : false;
const carerTimeline = computed(() => !serviceCountsTimeline && props.carerTimeline);
const familyTimeline = computed(() => !serviceCountsTimeline && props.familyTimeline);
const displayAvatar = (carerTimeline.value && !familyTimeline.value) || serviceCountsTimeline;

const time = computed(() => {
  if (!props.interaction || props.interaction.state === 'sticky') return '';
  const timeToDisplay = props.interaction.startAt || props.interaction.finishAt;
  if ((getDueAlarm.value || isSnoozed(props.interaction)) && timeToDisplay) {
    const timeToCalculateOverdue = props.interaction.finishAt
      || props.interaction.plannedFinishAt
      || new Date().toDateString();
    const diffTime = Date.now() - new Date(timeToCalculateOverdue).getTime();
    if (diffTime > 0) return overdueTimeToWords(diffTime);
  }
  return formatDisplayTime(timeToDisplay);
});

const timeClass = computed(() => {
  if (getDueAlarm.value) {
    return getDueAlarm.value.state;
  }
  return '';
});

const dueInDate = computed(() => {
  if (props.interaction.finishAt) {
    const dateFormat = props.assessments ? 'dd/MM/yyyy' : 'd MMM';
    return format(new Date(props.interaction.finishAt), dateFormat);
  }
  return '';
});

const getDueAlarm = computed(() => props.interaction.alarms?.find((alarm: Alarm) => alarm.alarmType === 'due' && !alarm.closedAt));

const responsiblePersonPhoto = computed(() => {
  const responsibleId = responsiblePersonId(props.interaction);
  if (!responsibleId) { return undefined; }
  return personStore.photo(responsibleId);
});

const clientAvatar = computed(() => {
  const { clientId } = props.interaction;
  if (!clientId) { return undefined; }
  return clientStore.photo(clientId);
});

const listEntryInner = computed(() => {
  if (props.compact) {
    return 'v-list-entry-inner__compact';
  }
  return 'v-list-entry-inner';
});

const isBespokeTop = computed(() => (props.compact ? '6px' : '-4px'));

const interactionTitle = props.interaction.name || props.interaction.displayName;

// Bulk close
const multiSelectRegister = useMultiSelectRegister();
const { bulkCloseEnabled, bulkInteractionIds } = storeToRefs(multiSelectRegister);
const { isSelectableForBulkClose, isSimilarToCurrentSelection } = multiSelectRegister;
const selectableForBulkClose = ref(serviceCountsTimeline ? isSelectableForBulkClose(props.interaction) : false);
const interactionSelected = ref(false);

const selectForBulkClose = () => {
  if (!bulkCloseEnabled.value || !selectableForBulkClose.value) return;
  interactionSelected.value = multiSelectRegister.toggleSelect(props.interaction);
  if (interactionSelected.value && route.name !== 'serviceCountList.timeline.bulkClose') {
    router.push({
      name: 'serviceCountList.timeline.bulkClose',
      params: { id: props.interaction.id },
      query: route.query,
    });
  }
  if (bulkInteractionIds.value.length === 0 && route.name === 'serviceCountList.timeline.bulkClose') {
    router.push({
      name: 'serviceCountList.timeline',
      params: route.params,
      query: route.query,
    });
  }
};

watch(() => bulkCloseEnabled.value, (newValue) => {
  if (!newValue) {
    interactionSelected.value = false;
  }
});

watch(() => bulkInteractionIds.value.length, () => {
  if (bulkCloseEnabled.value) {
    selectableForBulkClose.value = isSelectableForBulkClose(props.interaction);
  }
});

const { toast, ToastType, translate } = use.helpers();

const showNotSelectableToast = () => {
  if (!multiSelectRegister.bulkCloseEnabled) return;
  let message = '';
  if (!nourishStore.userCan('manageInteractionsForServiceId', props.interaction.serviceId)) {
    message = translate('timeline.interaction.readOnlyMessage');
  } else if (!isSimilarToCurrentSelection(props.interaction)) {
    message = translate('interaction.custom_service', { service_name: props.interaction.serviceName });
  }
  if (message) {
    toast(message, ToastType.Danger);
  }
};

const divOrRouterLink = computed(() => (bulkCloseEnabled.value ? 'div' : 'router-link'));
const toValue = computed(() => (bulkCloseEnabled.value ? '' : interactionLink(route, props.interaction, props.assessments)));


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(divOrRouterLink.value), {
    to: toValue.value,
    onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (selectableForBulkClose.value ? selectForBulkClose() : showNotSelectableToast()), ["stop"]))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["v-list-entry sp-interaction", `${interactionSelectedClasses.value} sp-interaction-${__props.interaction.id} sp-${__props.interaction.state}`]),
        id: `interaction-${__props.interaction.id}`
      }, [
        _createElementVNode("div", null, [
          _createElementVNode("div", {
            class: _normalizeClass([interactionSelectedClasses.value, listEntryInner.value])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(__props.compact ? 'v-content__compact' : 'v-content')
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", {
                  class: _normalizeClass(["sp-interaction-icon", `${hasResponsiblePeople.value && !__props.compact ? "v-icon-border vertical-line-bottom" : "v-icon-no-border"}`])
                }, [
                  (_unref(displayAvatar))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        _createVNode(BaseAvatar, {
                          photo: clientAvatar.value,
                          size: "small",
                          class: _normalizeClass(`sp-avatar-${__props.interaction.clientId} sp-client-avatar`)
                        }, null, 8, ["photo", "class"])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                        _createVNode(BaseIcon, {
                          name: _unref(categoryIconStore).getIconUrl(_unref(categoryCodename))?.value as string,
                          fontSize: "36px",
                          noHover: "",
                          compact: "",
                          width: "36px",
                          height: "36px",
                          backgroundColor: "white",
                          foregroundColor: "black",
                          foregroundHoverColor: "white",
                          class: "align"
                        }, null, 8, ["name"])
                      ])),
                  (isBespoke.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5))
                    : _createCommentVNode("", true)
                ], 2),
                (__props.interaction.nfcTagId)
                  ? (_openBlock(), _createBlock(BaseIcon, {
                      key: 0,
                      name: "quick-close-saved",
                      "no-hover": "",
                      "no-hover-background": "",
                      square: "",
                      size: "2x",
                      class: "v-close-tag-label sp-nfc-closed"
                    }))
                  : _createCommentVNode("", true),
                (__props.interaction.bulkCloseUuid)
                  ? (_openBlock(), _createBlock(BaseIcon, {
                      key: 1,
                      name: "group-close-saved",
                      "no-hover": "",
                      "no-hover-background": "",
                      square: "",
                      size: "2x",
                      class: "v-close-tag-label sp-group-closed"
                    }))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_6, [
                (carerTimeline.value && !familyTimeline.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                      _createVNode(ClientBadges, {
                        clientId: __props.interaction.clientId,
                        class: "v-client-badges"
                      }, null, 8, ["clientId"]),
                      _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(serviceCountsTimeline) ? fullName.value : preferredName.value), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(serviceCountsTimeline))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createElementVNode("div", _hoisted_10, _toDisplayString(preferredName.value), 1),
                      _createElementVNode("div", _hoisted_11, _toDisplayString(fullName.value), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_unref(serviceCountsTimeline))
                  ? (_openBlock(), _createBlock(ClientBadges, {
                      key: 2,
                      clientId: __props.interaction.clientId,
                      class: "v-client-badges"
                    }, null, 8, ["clientId"]))
                  : _createCommentVNode("", true),
                (!_unref(serviceCountsTimeline))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_unref(interactionTitle)), 1))
                  : _createCommentVNode("", true),
                (showConfidentialityBadge.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      _createVNode(ConfidentialBadge)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              (__props.compact && !__props.assessments)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["v-interaction-time-text__compact sp-time-text", timeClass.value])
                    }, _toDisplayString(dueInDate.value), 3),
                    (!isPlanned.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 0,
                          class: _normalizeClass(["v-state-icon__compact", interactionStateClasses.value])
                        }, null, 2))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["v-interaction-time-text__compact sp-time-text", timeClass.value])
                    }, _toDisplayString(time.value), 3)
                  ]))
                : (__props.assessments && __props.interaction.state === 'closed')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["v-interaction-time-text__assessment sp-time-text", timeClass.value])
                      }, _toDisplayString(dueInDate.value), 3),
                      _createElementVNode("div", {
                        class: _normalizeClass(["v-interaction-time-text__assessment sp-time-text", timeClass.value])
                      }, _toDisplayString(time.value), 3)
                    ]))
                  : (__props.assessments && __props.interaction.state === 'planned')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["v-interaction-time-text__assessment sp-time-text", timeClass.value])
                        }, _toDisplayString(dueInDate.value), 3)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["v-interaction-time-text sp-time-text", timeClass.value])
                        }, _toDisplayString(time.value), 3)
                      ])),
              (_unref(bulkCloseEnabled) && _unref(serviceCountsTimeline))
                ? (_openBlock(), _createBlock(BaseCheckbox, {
                    key: 4,
                    class: "sp-select-checkbox v-select-checkbox",
                    blackTick: "",
                    disabled: !selectableForBulkClose.value,
                    checked: _unref(bulkCloseEnabled) ? interactionSelected.value : false
                  }, null, 8, ["disabled", "checked"]))
                : _createCommentVNode("", true)
            ], 2),
            (nourishInstance.value?.instanceType === 'skin' && nourishInstance.value?.metadata)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "v-left-col" }, null, -1)),
                  _createElementVNode("div", _hoisted_19, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("span", null, _toDisplayString(_unref(safeTranslate)(`datasets.${nourishInstance.value.metadata?.location}`)), 1)
                      ]),
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("span", null, _toDisplayString(_unref(safeTranslate)(`datasets.${nourishInstance.value.metadata?.specificType}`)), 1)
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(["v-interaction-selected-pointer", interactionSelectedClasses.value])
          }, null, 2),
          (hasAdditionalInfo.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                _createElementVNode("div", {
                  class: _normalizeClass(__props.compact ? 'v-content__compact' : 'v-content')
                }, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", {
                      class: _normalizeClass(hasResponsiblePeople.value ? "v-icon-border top vertical-line-top" : "v-icon-no-border")
                    }, [
                      (hasResponsiblePeople.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_25, [
                            _createVNode(BaseAvatar, {
                              photo: responsiblePersonPhoto.value,
                              size: "small",
                              class: _normalizeClass(`sp-avatar-${_unref(responsiblePersonId)(__props.interaction)}`)
                            }, null, 8, ["photo", "class"]),
                            (hasResponsiblePeople.value > 1)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_26, " +" + _toDisplayString(__props.interaction.responsiblePersonIds.length - 1), 1))
                              : _createCommentVNode("", true)
                          ]))
                        : _createCommentVNode("", true)
                    ], 2)
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      (isClosedWithFilledParameters.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sortedParameters.value, (parameter) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: `${__props.interaction.id}-${parameter?.id}`
                              }, [
                                _createVNode(ParameterInfo, {
                                  parameter: parameter,
                                  clientId: __props.interaction.clientId
                                }, null, 8, ["parameter", "clientId"])
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      (hasPerformedComputedParameterValues.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(computedParameters.value, (parameter) => {
                              return (_openBlock(), _createElementBlock("div", {
                                key: `${__props.interaction.id}-${parameter?.id}`
                              }, [
                                _createVNode(ParameterInfo, {
                                  parameter: parameter,
                                  clientId: __props.interaction.clientId,
                                  "computed-parameter": ""
                                }, null, 8, ["parameter", "clientId"]),
                                (_unref(hasTotalScoreStyle)(parameter))
                                  ? (_openBlock(), _createElementBlock("span", {
                                      key: 0,
                                      class: _normalizeClass(_unref(getScoreCssClass)(parameter)),
                                      id: `sp-${parameter?.id}-value`
                                    }, _toDisplayString((parameter.coercedValue as CoercedChoice).value), 11, _hoisted_31))
                                  : _createCommentVNode("", true)
                              ]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", null, [
                        _createElementVNode("div", _hoisted_32, [
                          (showHandover.value)
                            ? (_openBlock(), _createBlock(HandoverFlag, {
                                key: 0,
                                "hide-checkbox": "",
                                size: "sm",
                                class: "v-note-handover-flag-small",
                                checked: false,
                                disabled: false
                              }))
                            : _createCommentVNode("", true),
                          (showNotePublic.value)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(__props.interaction.notePublic), 1))
                            : _createCommentVNode("", true)
                        ])
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_34, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["sp-interaction-state-class", interactionStateClasses.value])
                    }, null, 2)
                  ])
                ], 2)
              ]))
            : _createCommentVNode("", true)
        ])
      ], 10, _hoisted_1)
    ]),
    _: 1
  }, 8, ["to"]))
}
}

})