import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "v-header-options sp-interaction-header-options" }
const _hoisted_2 = { class: "header-option-text" }

import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import { storeToRefs } from 'pinia';
import useUserStore from '@/_shared/store/user';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { computed, ref } from 'vue';
import use from '@/_shared/compositionApi';
import ConfirmationModal from '@/_shared/components/modals/ConfirmationModal/ConfirmationModal.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionHeaderOptions',
  setup(__props) {

const currentInteractionStore = useCurrentInteractionStore();
const { translate } = use.helpers();

const {
  currentInteraction,
  isSticky,
  isCancelled,
  hasOldVersionSignatureParam,
  hasDataPoint,
  isNotBulkClose,
} = storeToRefs(currentInteractionStore);
const nourishStore = useUserStore();
const isCareplanReview = computed(() => !!currentInteraction.value?.needId);
const isNew = computed(() => !currentInteraction.value?.id);

const canUpdateExistingPlannedInteraction = computed(
  () => ((currentInteraction.value?.state === 'planned' || isSticky.value)
  && nourishStore.userCan('manageInteractionsForServiceId', currentInteraction.value?.serviceId)
  && !isNew.value),
);

const showSaveForLater = computed(() => (!isCareplanReview.value
    && !hasOldVersionSignatureParam.value)
  && (canUpdateExistingPlannedInteraction.value || isNew.value)
  && isNotBulkClose.value
  && (!hasDataPoint.value || nourishStore.getFeatureToggle('saveForLaterStates')));

const showConfirmationModal = ref(false);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (!_unref(isCancelled))
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["v-interaction-time-picker circular-border-left", showSaveForLater.value ? '' : 'circular-border-right'])
          }, [
            _cache[3] || (_cache[3] = _createElementVNode("span", { class: "header-option-text" }, " Scheduled Time: 20:00 ", -1)),
            _createVNode(BaseIcon, {
              name: "nsx-font-icon--settings",
              size: "lg",
              "no-hover": "",
              "foreground-color": "$v-color-black",
              compact: ""
            })
          ], 2))
        : _createCommentVNode("", true),
      (showSaveForLater.value)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "v-save-for-later circular-border-right",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (showConfirmationModal.value = true))
          }, [
            _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(translate)('common.save_for_later')), 1),
            _createVNode(BaseIcon, {
              name: "nr-icon-pencil",
              size: "lg",
              "no-hover": "",
              "foreground-color": "white",
              compact: ""
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    (showConfirmationModal.value)
      ? (_openBlock(), _createBlock(ConfirmationModal, {
          key: 0,
          onCloseModal: _cache[1] || (_cache[1] = ($event: any) => (showConfirmationModal.value = false)),
          "confirm-text": _unref(translate)('common.save'),
          "first-row-text": _unref(translate)('timeline.interaction.confirm_save_for_later'),
          onConfirm: _cache[2] || (_cache[2] = ($event: any) => ([_ctx.$parent?.$emit('saveInteraction', _unref(currentInteraction).state), showConfirmationModal.value = false]))
        }, null, 8, ["confirm-text", "first-row-text"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})