import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "v-interaction-header-container sp-interaction-details" }
const _hoisted_2 = { class: "v-header-left" }
const _hoisted_3 = { class: "v-interaction-title" }
const _hoisted_4 = { class: "v-header-right" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "v-nourish-instance-detail"
}
const _hoisted_7 = { class: "v-nourish-instance-detail" }

import { InteractionTimePicker } from '@/timeline/components';
import use from '@/_shared/compositionApi';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import useCurrentInteractionStore from '@/timeline/store/currentInteractionStore';
import { storeToRefs } from 'pinia';
import useMultiSelectRegister from '@/_shared/store/multiSelectRegister';
import { safeTranslate } from '@/_shared/translations/i18n'; // Fallback to codename
import { instancesStore } from '@/_shared/store/Instances';
import { NourishInstance } from '@/_shared/types/NourishInstance';
import BaseIcon from '@/_shared/components/general/BaseIcon.vue';
import InteractionHeaderOptions from './InteractionHeaderOptions.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'InteractionHeader',
  props: {
  iconUrl: {
    type: String,
    required: true,
  },
  notToday: {
    type: Boolean,
    default: false,
  },
  saveForLaterVisible: {
    type: Boolean,
    default: false,
  },
  stickyHeader: {
    type: Boolean,
    default: false,
  },
},
  emits: ['saveInteraction'],
  setup(__props) {

const currentInteractionStore = useCurrentInteractionStore();

const { currentInteraction, isCancelled } = storeToRefs(currentInteractionStore);
const { bulkClientIds } = storeToRefs(useMultiSelectRegister());

const route = useRoute();
const { clientStore } = use.store.clients();
const { translate } = use.helpers();



const isNotServiceCountRoute = computed(() => !(route.name as string).startsWith('serviceCountList'));
const bulkClientIdsCount = computed(() => bulkClientIds.value?.length);

const title = computed(() => {
  // todo get correct name format
  if (isNotServiceCountRoute.value) return isCancelled.value ? 'Cancelled Record - ' : `${currentInteraction.value.name}`;
  if (!bulkClientIdsCount.value) return clientStore.name(currentInteraction.value.clientId);
  const displayLimit = 3;
  const names = clientStore.names((bulkClientIds.value as []).slice(0, displayLimit).map(Number));
  if (bulkClientIdsCount.value <= displayLimit) return names;
  return `${names}${bulkClientIdsCount.value <= displayLimit ? '' : ` ${translate('common.and_other', { count: bulkClientIdsCount.value - displayLimit })}`}`;
});

const nourishInstance = computed(() => {
  if (currentInteraction.value.nourishInstanceId && currentInteraction.value.clientId) {
    return instancesStore.clientInstanceByInstanceId(currentInteraction.value.clientId, currentInteraction.value.nourishInstanceId) as NourishInstance;
  }
  return undefined;
});

const instanceDetail = computed(() => {
  if (nourishInstance.value && nourishInstance.value?.instanceType === 'skin') {
    const woundLocation = safeTranslate(`datasets.${nourishInstance.value?.metadata?.location}`);
    const woundType = safeTranslate(`datasets.${nourishInstance.value?.metadata?.specificType}`);
    return `${woundLocation} - ${woundType}`;
  }
  if (nourishInstance.value && nourishInstance.value?.instanceType === 'event_pws') {
    const specificEvent = safeTranslate(`datasets.${nourishInstance.value?.metadata?.specificType}`);
    const severity = safeTranslate(`datasets.${nourishInstance.value?.metadata?.severity}`);
    return `${specificEvent} - ${severity}`;
  }
  return undefined;
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["v-flex-column", __props.stickyHeader ? 'sp-interaction-header-sticky' : 'sp-interaction-header'])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (isNotServiceCountRoute.value)
          ? (_openBlock(), _createBlock(BaseIcon, {
              key: 0,
              name: __props.iconUrl,
              size: "2x",
              noHover: "",
              compact: "",
              backgroundColor: "white",
              foregroundColor: "black",
              foregroundHoverColor: "white",
              class: "v-service-icon"
            }, null, 8, ["name"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", null, _toDisplayString(title.value), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (!_unref(isCancelled) && !__props.stickyHeader)
          ? (_openBlock(), _createBlock(_unref(InteractionTimePicker), {
              key: 0,
              customDate: __props.notToday || __props.saveForLaterVisible
            }, null, 8, ["customDate"]))
          : _createCommentVNode("", true),
        (__props.stickyHeader)
          ? (_openBlock(), _createBlock(InteractionHeaderOptions, { key: 1 }))
          : _createCommentVNode("", true)
      ])
    ]),
    (nourishInstance.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", {
            class: _normalizeClass(["v-nourish-instance-row", { 'v-icon-padding': isNotServiceCountRoute.value }]),
            "data-id": "nourishInstance"
          }, [
            (instanceDetail.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(instanceDetail.value), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_7, _toDisplayString(nourishInstance.value.reference), 1)
          ], 2)
        ]))
      : _createCommentVNode("", true)
  ], 2))
}
}

})