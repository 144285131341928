import { ref, Ref } from 'vue';
import use from '@/_shared/compositionApi';
import { Datasets } from '@/_shared/services/datasetsApi';
import { CompositeOption, Options } from '@/_shared/types/baseSelect';
import { Dataset } from '@/timeline/types/Dataset';
import { mapOptions } from '@/timeline/helper/parametersHelper';
import { safeTranslate } from '@/_shared/translations/i18n';

const { translate } = use.helpers();

const sections = ['divisions', 'regions', 'organisationUnits', 'eventCategories', 'events', 'severities'] as const;
type Section = typeof sections[number];

const selectedValues: Record<Section, Ref<string>> = {
  divisions: ref<string>('all'),
  regions: ref<string>('all'),
  organisationUnits: ref<string>('all'),
  eventCategories: ref<string>('all'),
  events: ref<string>('all'),
  severities: ref<string>('all'),
};

const selectedSpecificOptions: Record<Section, Ref<Options>> = {
  divisions: ref<Options>([]),
  regions: ref<Options>([]),
  organisationUnits: ref<Options>([]),
  eventCategories: ref<Options>([]),
  events: ref<Options>([]),
  severities: ref<Options>([]),
};

const resetSelectedValues = () => {
  Object.values(selectedValues).forEach((value) => {
    value.value = 'all';
  });
  Object.values(selectedSpecificOptions).forEach((value) => {
    value.value = [];
  });
};

function isChecked(section: Section, value: string) {
  return selectedValues[section].value === value;
}

const checkboxClicked = (section: Section, checkboxValue: string) => {
  selectedValues[section].value = checkboxValue;
};

const sectionTitle = (section: string, specific = '') => {
  switch (section) {
    case 'events':
      return translate(`notifications.${specific}events`, { event_term_plural: window.currentOrganisationUnit.event_term_plural });
    case 'eventCategories':
      return translate(`notifications.${specific}event_categories`, { event_term: window.currentOrganisationUnit.event_term });
    case 'severities':
      return specific === 'specific_' ? translate(`notifications.${specific}severities`) : translate('events.severity');
    case 'organisationUnits':
      return translate(`notifications.${specific}services`, { event_term: window.currentOrganisationUnit.event_term });
    default:
      return translate(`notifications.${specific}${section}`);
  }
};

const getDatasetOptions = (dataSets: Datasets) => {
  const commonOptions: CompositeOption[] = [];
  const allOptions: CompositeOption[] = [];
  Object.values(dataSets).forEach((dataset: Dataset) => {
    commonOptions.push(...filterMapDataSetOptions(dataset?.favourites || [], dataset?.translatable ?? false));
    allOptions.push(...filterMapDataSetOptions(dataset?.items || [], dataset?.translatable ?? false));
  });
  return mapOptions(commonOptions, allOptions);
};

const getSectionOptions = (values: string[], translatable?: boolean) => {
  const allOptions: CompositeOption[] = [];
  values.forEach((value: string) => {
    allOptions.push(...filterMapDataSetOptions([value] || [], translatable ?? false));
  });
  return mapOptions([], allOptions);
};

const filterMapDataSetOptions = (optionItems: string[], translatable: boolean): CompositeOption[] => optionItems.map((item) => ({
  value: item,
  text: translatable ? getTranslationForCodename(item) : item,
}));

const getTranslationForCodename = (item: string): string => safeTranslate(`datasets.${item}`);

export {
  sections,
  Section,
  selectedSpecificOptions,
  resetSelectedValues,
  isChecked,
  checkboxClicked,
  sectionTitle,
  getDatasetOptions,
  getSectionOptions,
  getTranslationForCodename,
};
