import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "v-item" }
const _hoisted_2 = { class: "v-item__slot" }
const _hoisted_3 = { class: "v-item__title" }
const _hoisted_4 = {
  key: 0,
  class: "v-item__form"
}

import { computed, PropType, ref } from 'vue';

import { useDebounceFn } from '@vueuse/core';
import use from '@/_shared/compositionApi';
import { BaseButton, BaseIcon } from '@/_shared/components';
import { getTranslationForCodename, sections } from '@/notifications/services/notificationsFormHelper';
import useNotificationsStore from '@/_shared/store/notifications';
import type { NotificationConfiguration } from '@/notifications/types/NotificationConfiguration';
import type { OrganisationUnit } from '@/_shared/types/organisationUnit';


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationsListItem',
  props: {
  notificationConfiguration: {
    type: Object as PropType<NotificationConfiguration>,
    required: true,
  },
},
  emits: [
  'deleteEntry',
],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { translate } = use.helpers();
const isEditing = ref(false);
const notificationsStore = useNotificationsStore();

const savingInProgress = ref(false);
const saveDisabled = computed(() => !!(savingInProgress.value));
const configurationTitle = computed(() => {
  function getNamesByIds(ids: number[]): string[] {
    const items: OrganisationUnit[] = notificationsStore.organisationUnits;
    return items
      .filter((item) => ids.includes(item.id)) // Filter items whose id is in the ids array
      .map((item) => item.name); // Extract names from matched items
  }

  const listOrAll = (value: string[]) => value.map((item) => getTranslationForCodename(item)).join(', ');

  // Create configuration filters labels based on the sections.
  const configurationFilters = sections.map((key) => {
    const value = props.notificationConfiguration[key];

    // Check if the value is an array and has at least one item.
    if (Array.isArray(value) && value.length) {
      switch (key) {
        case 'divisions':
          return `${translate('notifications.divisions')}: ${value.join(', ')}`;
        case 'regions':
          return `${translate('notifications.regions')}: ${value.join(', ')}`;
        case 'organisationUnits':
          return `${translate('notifications.services')}: ${getNamesByIds(value as number[]).join(', ')}`;
        default:
          return listOrAll(value as string[]);
      }
    }

    return '';
  });

  // Check if all configuration filters are empty.
  const isConfigurationFiltersEmpty = () => sections.every((key) => {
    const value = props.notificationConfiguration[key];
    return Array.isArray(value) && value.length === 0;
  });

  if (isConfigurationFiltersEmpty()) {
    return translate('notifications.all_notifications');
  }

  return configurationFilters.filter((value) => value !== '').join(' - ');
});

const onEdit = useDebounceFn(async (notificationConfiguration: NotificationConfiguration) => {
  isEditing.value = !isEditing.value;
  console.log('Edit clicked', notificationConfiguration);
}, 300);

const onDelete = useDebounceFn(async (notificationConfiguration: NotificationConfiguration) => {
  emit('deleteEntry', notificationConfiguration);
}, 300);


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(configurationTitle.value), 1),
      _createVNode(_unref(BaseButton), {
        buttonType: "primary",
        size: "small",
        disabled: saveDisabled.value,
        onClicked: _cache[0] || (_cache[0] = ($event: any) => (_unref(onEdit)(__props.notificationConfiguration))),
        class: "transparent circle",
        title: _unref(translate)('notifications.edit_notification')
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseIcon), {
            name: "v-icon-edit",
            noHoverBackground: ""
          })
        ]),
        _: 1
      }, 8, ["disabled", "title"]),
      _createVNode(_unref(BaseButton), {
        buttonType: "primary",
        size: "small",
        disabled: saveDisabled.value,
        onClicked: _cache[1] || (_cache[1] = ($event: any) => (_unref(onDelete)(__props.notificationConfiguration))),
        class: "transparent circle",
        title: _unref(translate)('notifications.delete_notifications')
      }, {
        default: _withCtx(() => [
          _createVNode(_unref(BaseIcon), {
            name: "v-icon-delete-bin",
            noHoverBackground: ""
          })
        ]),
        _: 1
      }, 8, ["disabled", "title"])
    ]),
    (isEditing.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})